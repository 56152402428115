export const brokerDetails = [
  {
    image: "/Images/Brokers/default.webp",
  },
  {
    name: "Corey Tator",
    email: "corey.tator@luckytruck.com",
    phone: "+1 6014765055",
    image: "/Images/Brokers/Corey Tator.webp",
  },
  {
    name: "Hannah Burt Tator",
    email: "hannah.burt@luckytruck.com",
    phone: "+1 6036145551",
    image: "/Images/Brokers/Hannah.png",
  },
  {
    name: "Julie Zimmer",
    email: "julie.zimmer@luckytruck.com",
    phone: "(847) 431-1851",
    image: "/Images/Brokers/Julie Zimmer.webp",
  },
  {
    name: "Sarah Hayman",
    email: "sarah.hayman@luckytruck.com",
    phone: "+1 6012029985",
    image: "/Images/Brokers/Sarah.png",
  },
  {
    name: "Erin Malenfant",
    email: "erin.malenfant@luckytruck.com",
    phone: "+1 6467771254",
    image: "/Images/Brokers/Erin.png"
  },
  {
    name: "Deb Radcliff",
    email: "deb.radcliff@luckytruck.com",
    phone: "+1 9522225420",
    image: "/Images/Brokers/Deb.png"
  }
];
